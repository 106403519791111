import { Box, Flex, Heading } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import AppContainer from 'src/common/AppContainer'
import { H2, Hyperlink } from 'src/typography/typography'

export default function Custom404() {
  const router = useRouter()

  return (
    <AppContainer>
      <Box
        mx='auto'
        mb={4}
        textAlign='center'
      >
        <H2 as='h1'>
          404
        </H2>
      </Box>
      <Flex justify='center' align='center'>
        <img
          src='https://i.pinimg.com/originals/f8/7f/f5/f87ff51406bb1fe624220f59c6b8160b.gif'
          width='320'
          height='240'
        />
      </Flex>
      <Box
        mx='auto'
        mt={4}
        textAlign='center'
      >
        <Hyperlink onClick={router.back} variant='hyperlink'>
          ← Back
        </Hyperlink>
      </Box>
    </AppContainer>
  )
}